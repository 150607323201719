exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-preview-tsx": () => import("./../../../src/pages/blog-preview.tsx" /* webpackChunkName: "component---src-pages-blog-preview-tsx" */),
  "component---src-pages-blog-search-tsx": () => import("./../../../src/pages/blog/search.tsx" /* webpackChunkName: "component---src-pages-blog-search-tsx" */),
  "component---src-pages-cookie-policy-mdx": () => import("./../../../src/pages/cookie-policy.mdx" /* webpackChunkName: "component---src-pages-cookie-policy-mdx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-data-privacy-framework-mdx": () => import("./../../../src/pages/data-privacy-framework.mdx" /* webpackChunkName: "component---src-pages-data-privacy-framework-mdx" */),
  "component---src-pages-demo-confirmation-tsx": () => import("./../../../src/pages/demo-confirmation.tsx" /* webpackChunkName: "component---src-pages-demo-confirmation-tsx" */),
  "component---src-pages-dmca-notice-mdx": () => import("./../../../src/pages/dmca-notice.mdx" /* webpackChunkName: "component---src-pages-dmca-notice-mdx" */),
  "component---src-pages-dpa-mdx": () => import("./../../../src/pages/dpa.mdx" /* webpackChunkName: "component---src-pages-dpa-mdx" */),
  "component---src-pages-features-ab-testing-tsx": () => import("./../../../src/pages/features/ab-testing.tsx" /* webpackChunkName: "component---src-pages-features-ab-testing-tsx" */),
  "component---src-pages-features-autopilot-offers-tsx": () => import("./../../../src/pages/features/autopilot-offers.tsx" /* webpackChunkName: "component---src-pages-features-autopilot-offers-tsx" */),
  "component---src-pages-features-custom-branding-tsx": () => import("./../../../src/pages/features/custom-branding.tsx" /* webpackChunkName: "component---src-pages-features-custom-branding-tsx" */),
  "component---src-pages-features-customer-exit-surveys-tsx": () => import("./../../../src/pages/features/customer-exit-surveys.tsx" /* webpackChunkName: "component---src-pages-features-customer-exit-surveys-tsx" */),
  "component---src-pages-features-customer-segmentation-tsx": () => import("./../../../src/pages/features/customer-segmentation.tsx" /* webpackChunkName: "component---src-pages-features-customer-segmentation-tsx" */),
  "component---src-pages-features-easy-setup-tsx": () => import("./../../../src/pages/features/easy-setup.tsx" /* webpackChunkName: "component---src-pages-features-easy-setup-tsx" */),
  "component---src-pages-features-offers-and-deflections-tsx": () => import("./../../../src/pages/features/offers-and-deflections.tsx" /* webpackChunkName: "component---src-pages-features-offers-and-deflections-tsx" */),
  "component---src-pages-how-it-works-demo-lp-tsx": () => import("./../../../src/pages/how-it-works-demo-lp.tsx" /* webpackChunkName: "component---src-pages-how-it-works-demo-lp-tsx" */),
  "component---src-pages-how-it-works-discount-lp-tsx": () => import("./../../../src/pages/how-it-works-discount-lp.tsx" /* webpackChunkName: "component---src-pages-how-it-works-discount-lp-tsx" */),
  "component---src-pages-how-it-works-help-lp-tsx": () => import("./../../../src/pages/how-it-works-help-lp.tsx" /* webpackChunkName: "component---src-pages-how-it-works-help-lp-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-digital-media-tsx": () => import("./../../../src/pages/industries/digital-media.tsx" /* webpackChunkName: "component---src-pages-industries-digital-media-tsx" */),
  "component---src-pages-industries-ecommerce-tsx": () => import("./../../../src/pages/industries/ecommerce.tsx" /* webpackChunkName: "component---src-pages-industries-ecommerce-tsx" */),
  "component---src-pages-industries-saas-tsx": () => import("./../../../src/pages/industries/saas.tsx" /* webpackChunkName: "component---src-pages-industries-saas-tsx" */),
  "component---src-pages-integrate-confirmation-tsx": () => import("./../../../src/pages/integrate-confirmation.tsx" /* webpackChunkName: "component---src-pages-integrate-confirmation-tsx" */),
  "component---src-pages-integrations-bold-offer-modal-tsx": () => import("./../../../src/pages/integrations/BoldOfferModal.tsx" /* webpackChunkName: "component---src-pages-integrations-bold-offer-modal-tsx" */),
  "component---src-pages-integrations-bold-tsx": () => import("./../../../src/pages/integrations/bold.tsx" /* webpackChunkName: "component---src-pages-integrations-bold-tsx" */),
  "component---src-pages-integrations-braintree-tsx": () => import("./../../../src/pages/integrations/braintree.tsx" /* webpackChunkName: "component---src-pages-integrations-braintree-tsx" */),
  "component---src-pages-integrations-chargebee-tsx": () => import("./../../../src/pages/integrations/chargebee.tsx" /* webpackChunkName: "component---src-pages-integrations-chargebee-tsx" */),
  "component---src-pages-integrations-hubspot-tsx": () => import("./../../../src/pages/integrations/hubspot.tsx" /* webpackChunkName: "component---src-pages-integrations-hubspot-tsx" */),
  "component---src-pages-integrations-intercom-tsx": () => import("./../../../src/pages/integrations/intercom.tsx" /* webpackChunkName: "component---src-pages-integrations-intercom-tsx" */),
  "component---src-pages-integrations-klaviyo-tsx": () => import("./../../../src/pages/integrations/klaviyo.tsx" /* webpackChunkName: "component---src-pages-integrations-klaviyo-tsx" */),
  "component---src-pages-integrations-maxio-tsx": () => import("./../../../src/pages/integrations/maxio.tsx" /* webpackChunkName: "component---src-pages-integrations-maxio-tsx" */),
  "component---src-pages-integrations-naviga-tsx": () => import("./../../../src/pages/integrations/naviga.tsx" /* webpackChunkName: "component---src-pages-integrations-naviga-tsx" */),
  "component---src-pages-integrations-paddle-tsx": () => import("./../../../src/pages/integrations/paddle.tsx" /* webpackChunkName: "component---src-pages-integrations-paddle-tsx" */),
  "component---src-pages-integrations-recharge-tsx": () => import("./../../../src/pages/integrations/recharge.tsx" /* webpackChunkName: "component---src-pages-integrations-recharge-tsx" */),
  "component---src-pages-integrations-recurly-tsx": () => import("./../../../src/pages/integrations/recurly.tsx" /* webpackChunkName: "component---src-pages-integrations-recurly-tsx" */),
  "component---src-pages-integrations-salesforce-tsx": () => import("./../../../src/pages/integrations/salesforce.tsx" /* webpackChunkName: "component---src-pages-integrations-salesforce-tsx" */),
  "component---src-pages-integrations-segment-tsx": () => import("./../../../src/pages/integrations/segment.tsx" /* webpackChunkName: "component---src-pages-integrations-segment-tsx" */),
  "component---src-pages-integrations-shopify-tsx": () => import("./../../../src/pages/integrations/shopify.tsx" /* webpackChunkName: "component---src-pages-integrations-shopify-tsx" */),
  "component---src-pages-integrations-stripe-tsx": () => import("./../../../src/pages/integrations/stripe.tsx" /* webpackChunkName: "component---src-pages-integrations-stripe-tsx" */),
  "component---src-pages-integrations-tsx": () => import("./../../../src/pages/integrations.tsx" /* webpackChunkName: "component---src-pages-integrations-tsx" */),
  "component---src-pages-integrations-zuora-tsx": () => import("./../../../src/pages/integrations/zuora.tsx" /* webpackChunkName: "component---src-pages-integrations-zuora-tsx" */),
  "component---src-pages-lp-demo-confirmation-tsx": () => import("./../../../src/pages/lp/demo-confirmation.tsx" /* webpackChunkName: "component---src-pages-lp-demo-confirmation-tsx" */),
  "component---src-pages-meeting-confirmation-tsx": () => import("./../../../src/pages/meeting-confirmation.tsx" /* webpackChunkName: "component---src-pages-meeting-confirmation-tsx" */),
  "component---src-pages-press-seed-round-sandalphon-capital-mdx": () => import("./../../../src/pages/press/seed-round-sandalphon-capital.mdx" /* webpackChunkName: "component---src-pages-press-seed-round-sandalphon-capital-mdx" */),
  "component---src-pages-press-top-subscription-management-software-mdx": () => import("./../../../src/pages/press/top-subscription-management-software.mdx" /* webpackChunkName: "component---src-pages-press-top-subscription-management-software-mdx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-roi-calculator-tsx": () => import("./../../../src/pages/roi-calculator.tsx" /* webpackChunkName: "component---src-pages-roi-calculator-tsx" */),
  "component---src-pages-solutions-convert-tsx": () => import("./../../../src/pages/solutions/convert.tsx" /* webpackChunkName: "component---src-pages-solutions-convert-tsx" */),
  "component---src-pages-solutions-customer-portal-tsx": () => import("./../../../src/pages/solutions/customer-portal.tsx" /* webpackChunkName: "component---src-pages-solutions-customer-portal-tsx" */),
  "component---src-pages-solutions-retain-tsx": () => import("./../../../src/pages/solutions/retain.tsx" /* webpackChunkName: "component---src-pages-solutions-retain-tsx" */),
  "component---src-pages-subprocessors-mdx": () => import("./../../../src/pages/subprocessors.mdx" /* webpackChunkName: "component---src-pages-subprocessors-mdx" */),
  "component---src-pages-teams-ceos-founders-tsx": () => import("./../../../src/pages/teams/ceos-founders.tsx" /* webpackChunkName: "component---src-pages-teams-ceos-founders-tsx" */),
  "component---src-pages-teams-customer-success-tsx": () => import("./../../../src/pages/teams/customer-success.tsx" /* webpackChunkName: "component---src-pages-teams-customer-success-tsx" */),
  "component---src-pages-teams-growth-marketing-tsx": () => import("./../../../src/pages/teams/growth-marketing.tsx" /* webpackChunkName: "component---src-pages-teams-growth-marketing-tsx" */),
  "component---src-pages-teams-product-tsx": () => import("./../../../src/pages/teams/product.tsx" /* webpackChunkName: "component---src-pages-teams-product-tsx" */),
  "component---src-pages-terms-of-service-mdx": () => import("./../../../src/pages/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-terms-of-service-mdx" */),
  "component---src-templates-blog-index-template-tsx": () => import("./../../../src/templates/blogIndexTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-index-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

